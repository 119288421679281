<template>
  <div>
    <div class="wz">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>职位列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="box">
      <div>
        <div style="float:left;">
          <el-form :inline="true" :model="searchform">
            <el-form-item label="公司">
              <el-select
                v-model="memberId"
                clearable
                filterable
                remote
                placeholder="请输入公司名称"
                :loading="loading1"
                style="width: 150px"
                :remote-method="remoteMethod"
                @change="
                  pageNum = 1;
                  getPosition();
                "
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职位">
              <el-input
                placeholder="请输入职位名称"
                v-model.trim="workName"
                @keyup.enter.native="
                  pageNum = 1;
                  getPosition();
                "
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-search lh"
                  @click="getPosition"
                ></i>
              </el-input>
            </el-form-item>
            <el-form-item label="合理情况">
              <el-select
                v-model="status"
                placeholder="全部"
                style="width: 150px"
                @change="
                  pageNum = 1;
                  getPosition();
                "
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="合理" value="0"></el-option>
                <el-option label="不合理" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开启状态">
              <el-select
                v-model="isAction"
                @change="
                  pageNum = 1;
                  getPosition();
                "
                placeholder="全部"
                style="width: 150px"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="已开启" value="1"></el-option>
                <el-option label="已关闭" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职位状态">
              <el-select
                v-model="tagId"
                @change="
                  pageNum = 1;
                  getPosition();
                "
                placeholder="全部"
                clearable
                style="width: 150px"
              >
                <el-option
                  v-for="item in jobTagsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职位性质">
              <el-select
                v-model="workType"
                @change="
                  pageNum = 1;
                  getPosition();
                "
                placeholder="全部"
                clearable
                style="width: 150px"
              >
                <el-option label="不限" :value="3"></el-option>
                <el-option label="全职" :value="1"></el-option>
                <el-option label="灵活用工岗位" :value="2"></el-option>
                <el-option label="共享用工" :value="5"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div style="float:right;">
          <el-button type="primary" :loading="exporting" @click="showexport=true"  icon="el-icon-bottom">导出</el-button>
        </div>
      </div>
      <div class="resumelist">
        <el-table
          :data="positionData"
          :header-cell-style="{
            'font-weight': 'bold',
            color: '#555555',
            padding: '6px 0',
          }"
          style="width: 100%"
          v-loading="loading"
          row-key="id"
          border
        >
          <el-table-column prop="id" align="center" label="ID" width="100">
          </el-table-column>
          <el-table-column
            prop="workName"
            align="center"
            label="职位"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="companyName"
            align="center"
            label="企业名"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="industryName"
            align="center"
            label="所属行业"
            width="200"
          >
          </el-table-column>
          <el-table-column prop="" align="center" label="薪资范围" width="150">
            <template slot-scope="scope">
              {{ wagesList[scope.row.wagesId].desc }}
            </template>
          </el-table-column>
          <el-table-column
            prop=""
            align="center"
            label="联系人信息"
            width="200"
          >
            <template slot-scope="scope">
              <div class="user-info-wage" slot="reference">
                <p class="name">
                  {{ scope.row.contacts }} · {{ scope.row.contactsPhone }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" align="center" label="其他信息" width="200">
            <template slot-scope="scope">
              <div class="tags-warpper">
                <div class="tags">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="最低学历"
                    placement="top-start"
                  >
                    <el-tag size="mini">{{ scope.row.workEducation }}</el-tag>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="工作经验"
                    placement="top-start"
                  >
                    <el-tag type="success" size="mini">{{
                      scope.row.workYears
                    }}</el-tag>
                  </el-tooltip>
                  <!-- <el-tag type="warning" size="mini">{{
                    scope.row.address
                  }}</el-tag> -->
                </div>
                <div class="no-tag"></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            align="center"
            label="合理情况"
            width="100"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == false" type="info">合理</el-tag>
              <el-tag v-else type="info">不合理</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="tagName" align="center" label="职位状态">
            <template slot-scope="scope">
              <el-tag
                type="info"
                size="mini"
                v-for="(item, index) in scope.row.tagNames"
                :key="index"
                >{{ item }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column prop="" align="center" label="状态">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isAction"
                :active-value="true"
                :inactive-value="false"
                active-color="#404040"
                inactive-color="#b8b8b8"
                active-text=""
                :disabled="scope.row.status == true"
                inactive-text=""
                @change="changeActionWork($event, scope.row.id)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="workName"
            align="center"
            fixed="right"
            label="操作"
            width="260"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="openTagNoPage(scope.row)"
                >更改</el-button
              >
              <el-button
                type="primary"
                @click="openDialog(scope.row.id)"
                >审核</el-button
              >
              <el-button
                type="danger"
                @click="deleteWorkById(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <app-page
        :total="total"
        :pageSize="pageSize"
        :current="pageNum"
        @handleCurrentChange="currentchange"
        @handleSizeChange="handchange"
      ></app-page>
    </div>
    <!-- 审核弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close="cancel"
    >
      <el-form
        :label-position="labelPosition"
        label-width="80px"
        :model="form"
        :rules="rules"
        ref="formRef"
      >
        <!-- <el-form-item label="用户名" prop="account">
          <el-input v-model="formAddAdmin.account"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="formAddAdmin.password"></el-input>
        </el-form-item> -->
        <el-form-item label="理由" prop="reason">
          <el-input v-model="form.reason"></el-input>
        </el-form-item>
        <el-form-item class="flex justify-content-end">
          <el-button type="success" v-preventReClick @click="examine(0)"
            >合理</el-button
          >
          <el-button type="primary" v-preventReClick @click="examine(1)"
            >不合理</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="更改职位状态"
      :visible.sync="tagNoPageVisible"
      :before-close="closeTagVisible"
      width="500px"
    >
      <el-select
        v-model="jobTagForm.workTags"
        multiple
        style="width: 380px"
        placeholder="请选择职位所属标签（可多选）"
      >
        <el-option
          v-for="item in jobTagsList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tagNoPageVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveJobTag">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="导出"
      :visible.sync="showexport"
      width="500px"
    >
      <div>
        <div style="margin-bottom:10px;">选择导出时间段：</div>
        <el-date-picker
        v-model="exportdata"
        type="daterange"
        range-separator="至"
        value-format="yyyy-MM-dd"
        start-placeholder="开始日期"
        :picker-options="pickerOptions"
        end-placeholder="结束日期">
      </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeexport">取 消</el-button>
        <el-button type="primary" @click="putexport">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import companyRequest from "../../api/company";
import _api from "@/api/index";
import apiUrl from "@/api/public";
import axios from 'axios';
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(v) {
          return v.getTime() < new Date("2019-01-01").getTime() - 86400000;
        }
      },
      exporting:false,
      exportdata:null,
      showexport:false,
      loading: true,
      loading1: false,
      loading2: false,
      tagNoPageVisible: false, //更改职位状态
      jobTagsList: [], //职位标签列表
      jobTagForm: {
        //职位对应标签
        workId: null,
        workTags: [],
      },
      percentage: 40,
      size: 50,
      total: 0,
      pageSize: 10,
      pageNum: 1,
      workType:null,
      memberId: "",
      workName: "",
      positionData: [],
      status: null,
      isAction: null,
      tagId: null,
      searchform: {},
      options: [],
      value: [],
      list: [],
      states: [],
      dialogVisible: false,
      form: {
        reason: "",
      },
      rules: {
        reason: [{ required: true, message: "理由不能为空", trigger: "blur" }],
      },
      labelPosition: "right",
      workId: "",
      wagesList: [
        //薪资标准
        { id: null, desc: "面议" },
        { id: 1, desc: "1000-2000元" },
        { id: 2, desc: "2000-3000元" },
        { id: 3, desc: "3000-5000元" },
        { id: 4, desc: "5000-8000元" },
        { id: 5, desc: "8000-12000元" },
        { id: 6, desc: "12000-15000元" },
        { id: 7, desc: "15000-20000元" },
        { id: 8, desc: "20000元以上" },
      ],
    };
  },
  components: {},
  created() {
    this.getPosition();
    // this.getCompany();
    this.selectTagNoPage();
  },
  methods: {
    closeexport(){
      this.showexport=false
      this.exportdata=null
    },
    putexport(){
      if(this.exportdata==null || this.exportdata==''){
        this.$message.error("请选择时间段")
        return
      }
      this.exporting=true
      //window.location.href=`http://192.168.16.184:8085/${apiUrl.exportWork}?createTime=${this.exportdata[0]}&endTime=${this.exportdata[1]}`
      axios.post("/api/"+apiUrl.exportWork,{createTime:this.exportdata[0],endTime:this.exportdata[1]},{ responseType: 'blob' }).then(res=>{
          var blob = new Blob([res.data]);
          this.exporting=false
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = new Date().getTime() + '.xlsx'; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
      })
      this.showexport=false
      this.exportdata=null
    },
    //打开职位标签弹框
    openTagNoPage(row) {
      this.jobTagForm.workId = row.id;
      if (row.tags) {
        this.jobTagForm.workTags = row.tags.split(",");
        this.jobTagForm.workTags = this.jobTagForm.workTags.map((item) => {
          return (item = parseInt(item));
        });
      }
      this.tagNoPageVisible = true;
    },
    closeTagVisible() {
      console.log(11);
      this.jobTagForm.workId = null;
      this.jobTagForm.workTags = [];
      this.tagNoPageVisible = false;
    },
    //保存职位标签
    saveJobTag() {
      // console.log('jobTagForm', this.jobTagForm)
      let obj = {};
      obj = JSON.parse(JSON.stringify(this.jobTagForm));
      obj.workTags = obj.workTags.join(",");
      console.log("obj", obj);
      companyRequest
        .changeWorkTags(obj)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("保存成功~");
            this.getPosition();
            this.jobTagForm.workId = null;
            this.jobTagForm.workId = [];
            this.tagNoPageVisible = false;
          }
        })
        .catch((err) => {
          this.$message.error(err);
          this.jobTagForm.workId = null;
          this.jobTagForm.workId = [];
          this.tagNoPageVisible = false;
        });
    },
    //获取职位标签
    selectTagNoPage() {
      companyRequest
        .selectTagNoPage()
        .then((res) => {
          if (res.code === 200) {
            this.jobTagsList = res.data;
          }
        })
        .catch((err) => {
          this.$message.error(err);
          this.tagNoPageVisible = false;
        });
    },
    handchange(data) {
      //分页Size变化
      this.pageSize = data;
      this.pageNum = 1;
      this.getPosition();
    },
    currentchange(data) {
      //当前页变化
      this.pageNum = data;
      this.getPosition();
    },
    getPosition() {
      _api
        .post(apiUrl.selectWorkByMemberId, {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          memberId: this.memberId,
          workName: this.workName,
          workType: this.workType,
          status: this.status,
          isAction: this.isAction,
          tagId: this.tagId,
        })
        .then((res) => {
          if (res.success) {
            console.log(res);
            this.positionData = res.data.list;
            this.positionData.forEach((item) => {
              if (item.tagName) {
                item.tagNames = item.tagName.split(",");
              }
            });
            console.log("positionData", this.positionData);
            this.total = res.data.total;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompany() {
      _api
        .get(apiUrl.queryMemberNotPage, {})
        .then((res) => {
          if (res.success) {
            this.states = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteWorkById(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        _api
          .get(apiUrl.deleteWorkById, {
            workId: id,
          })
          .then((res) => {
            if (res.success) {
              this.$message.success("删除成功！");
              this.getPosition();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    openDialog(id) {
      this.dialogVisible = true;
      this.workId = id;
    },
    examine(status) {
      if (status == 1 && this.form.reason == "") {
        this.$message.error("请填写拒绝理由！");
        return;
      }
      console.log(status, this.form.reason);
      _api
        .post(apiUrl.WorkChangeStatus, {
          workId: this.workId,
          status: status,
          reason: this.form.reason,
        })
        .then((res) => {
          if (res.success) {
            this.getPosition();
            this.cancel();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeActionWork(isAction, workIds) {
      _api
        .post(apiUrl.changeActionWork, {
          workIds: [workIds],
          isAction: isAction,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success("操作成功！");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancel() {
      this.dialogVisible = false;
      this.form.reason = "";
      this.workId = "";
    },
    remoteMethod(query) {
      if (query !== "") {
        _api
          .get(apiUrl.queryMemberNotPage, {
            name:query
          })
          .then((res) => {
            if (res.success) {
              let option = res.data;
              this.loading1 = true;
              setTimeout(() => {
                this.loading1 = false;
                this.options = option.filter((item) => {
                  return item.userName.indexOf(query) > -1;
                });
              }, 200);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.options = [];
      }
    },
  },
};
</script>
<style scoped>
@import "../../assets/css/resume.css";
</style>
<style lang="scss" scoped>
</style>